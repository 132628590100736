/**
 *  项目的配置文件
 */
// export const apiUrl = 'https://jbbcdev.slodon.cn/';//演示站请求接口请求地址
// export const apiUrl = 'https://huayuinterpc.slodon.cn/';//接口请求地址/
export const apiUrl = 'https://www.apdodo.com/';//接口请求地址/
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const chatUrl = 'wss://im.55sld.com';//客服地址
export const mUrl = 'https://m.apdodo.com/'//移动端网页地址
export const title = '安平网多多';//浏览器顶部title
export const gdKey = '';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试


// ** copyright *** slodon *** version-v3.5 *** date-2021-11-18 ***主版本v3.5



