<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
  @import "./style/reset.scss";
  @import "./style/base.scss";
  //放大镜位置
  .mouse-cover-canvas {
    position: absolute;
    top: 173px !important;
    left: 740px !important;
  }
  //富文本样式
  .rich_text {
		white-space: pre-wrap;
	}
  .ql-align-center {
		text-align: center;
	}
  .ql-align-left {
		text-align: left;
	}
	.ql-align-right {
		text-align: right;
	}
  .ql-font-monospace {
	    font-family: Monaco,Courier New,monospace;
	}
	.ql-align-justify {
		white-space: pre-wrap;
	}
  .ql-size-huge {
		font-size: 2.5em;
	}
	.ql-size-large {
	    font-size: 1.5em;
	}
</style>
